/* eslint-disable camelcase */
/* eslint-env jest */
import mapParameter from './mapParameter';

const createOption = (value, options = []) => ({
  CurrentOpt: value,
  Options: options,
});

const state1 = {
  uid_videoin_c0_s3_h264_smartstream_qualitypriority: createOption('1'),
  uid_videoin_c0_s3_h264_smartstream_mode: createOption('autotracking'),
  uid_videoin_c0_s3_h264_smartstream_win_i0_enable: createOption('0'),
  uid_videoin_c0_s3_h264_smartstream_win_i0_home: createOption('150,110'),
  uid_videoin_c0_s3_h264_smartstream_win_i0_size: createOption('100x75'),
  uid_videoin_c0_s3_h264_smartstream_win_i1_enable: createOption('0'),
  uid_videoin_c0_s3_h264_smartstream_win_i1_home: createOption('150,110'),
  uid_videoin_c0_s3_h264_smartstream_win_i1_size: createOption('100x75'),
  uid_videoin_c0_s3_h264_smartstream_win_i2_enable: createOption('0'),
  uid_videoin_c0_s3_h264_smartstream_win_i2_home: createOption('150,110'),
  uid_videoin_c0_s3_h264_smartstream_win_i2_size: createOption('100x75'),
};

const state = {
  uid_event_enable: createOption('2'),
  uid_event_i0_enable: createOption('0'),
  uid_event_i0_weekday: createOption(127),
  uid_event_i0_exttriggerstatus: createOption('trigger'),
  uid_vadp_schedule_i0_begintime: createOption('18:00'),
  uid_vadp_schedule_i0_endtime: createOption('23:55'),
  uid_recording_i0_maxduration: createOption('60'),
  uid_recording_i1_maxduration: createOption('120'),
  uid_media_i0_videoclip_maxduration: createOption('60'),
};

const state_with_capability_before = {
  uid_ptz_pan_speed_capability: createOption('127'),
  uid_ptz_pan_speed: createOption('0'),
};

const state_with_capability_after = {
  uid_ptz_pan_speed: createOption('0'),
  uid_ptz_pan_speed_capability: createOption('127'),
};

const store = {
  state: {
    encoder: {},
  },
  commit: jest.fn(),
  dispatch: jest.fn(),
};

beforeAll(() => {
  global.$_store = store;
});

describe('version 4', () => {
  beforeEach(() => {
    store.state.setting = {
      1: {
        ...state_with_capability_after,
      },
    };
  });
  describe('after capability', () => {
    it('should put capability inside', () => {
      const mapped = mapParameter(store.state, 1);
      expect(mapped.ptz.pan.speed.model).toEqual('0');
      expect(mapped.ptz.pan.speed.capability).toEqual('127');
    });
  });
});

describe('version 3', () => {
  beforeEach(() => {
    store.state.setting = {
      1: {
        ...state_with_capability_before,
      },
    };
  });
  describe('before capability', () => {
    it('should put capability inside', () => {
      const mapped = mapParameter(store.state, 1);
      expect(mapped.ptz.pan.speed.model).toEqual('0');
      expect(mapped.ptz.pan.speed.capability).toEqual('127');
    });
  });
});

describe('version 2', () => {
  beforeEach(() => {
    store.state.setting = {
      1: {
        ...state1,
      },
    };
  });
  describe('array key transform', () => {
    it('should have arrays', () => {
      const mapped = mapParameter(store.state, 1);
      expect(mapped.videoin.channel.s[3].h264.smartstream.win.i[1].home.model).toEqual('150,110');
    });
  });
});

describe('version 1', () => {
  beforeEach(() => {
    store.state.setting = {
      1: {
        ...state,
      },
    };
  });
  describe('begintime transform', () => {
    it('should transform date string to trigger status string', () => {
      const mapped = mapParameter(store.state, 1);
      mapped.vadp.schedule.i[0].begintime.model = '2019/01/01 18:00';
      expect(store.commit).toBeCalledWith('encoder/writeSettingDirty', {
        index: 1,
        uid_vadp_schedule_i0_begintime: '18:00',
      });
    });
  });

  describe('endtime transform', () => {
    it('should transform date string to trigger status string', () => {
      const mapped = mapParameter(store.state, 1);
      mapped.vadp.schedule.i[0].endtime.model = '2019/01/01 23:59';
      expect(store.commit).toBeCalledWith('encoder/writeSettingDirty', {
        index: 1,
        uid_vadp_schedule_i0_endtime: '23:59',
      });
    });
  });

  describe('exttriggerstatus transform', () => {
    it('should transform binary raw data to array of trigger status', () => {
      const mapped = mapParameter(store.state, 1);
      expect(mapped.event.i[0].exttriggerstatus.model).toEqual(['trigger']);
    });

    it('should transform array to trigger status string', () => {
      const mapped = mapParameter(store.state, 1);
      mapped.event.i[0].exttriggerstatus.model = ['trigger', 'normal~trigger'];
      expect(store.commit).toBeCalledWith('encoder/writeSettingDirty', {
        index: 1,
        uid_event_i0_exttriggerstatus: 'trigger,normal~trigger',
      });
    });
  });

  describe('weekday transform', () => {
    it('should transform binary raw data to array of weekdays', () => {
      const mapped = mapParameter(store.state, 1);
      expect(mapped.event.i[0].weekday.model).toEqual([0, 1, 2, 3, 4, 5, 6]);
    });

    it('should transform array to binary for weekdays', () => {
      const mapped = mapParameter(store.state, 1);
      mapped.event.i[0].weekday.model = [0, 5];
      expect(store.commit).toBeCalledWith('encoder/writeSettingDirty', {
        index: 1,
        uid_event_i0_weekday: 66,
      });
    });
  });

  describe('maxduration transform', () => {
    it('should transform seconds to minutes for recording_ix_maxduration', () => {
      const mapped = mapParameter(store.state, 1);
      expect(mapped.recording.i[0].maxduration.model).toBe(1);
      expect(mapped.recording.i[1].maxduration.model).toBe(2);
    });

    it('should transform minutes to seconds for recording_ix_maxduration', () => {
      const mapped = mapParameter(store.state, 1);
      mapped.recording.i[0].maxduration.model = 3;
      expect(store.commit).toBeCalledWith('encoder/writeSettingDirty', {
        index: 1,
        uid_recording_i0_maxduration: 180,
      });
    });

    it('should not transform seconds to minutes for media_ix_videoclip_maxduration', () => {
      const mapped = mapParameter(store.state, 1);
      expect(mapped.media.i[0].videoclip.maxduration.model).toBe('60');
    });

    it('should not transform minutes to seconds for media_ix_videoclip_maxduration', () => {
      const mapped = mapParameter(store.state, 1);
      mapped.media.i[0].videoclip.maxduration.model = 3;
      expect(store.commit).toBeCalledWith('encoder/writeSettingDirty', {
        index: 1,
        uid_media_i0_videoclip_maxduration: 3,
      });
    });
  });

  describe('mapParameter', () => {
    beforeEach(() => {
      jest.resetAllMocks();
    });
    it('getter', () => {
      const mapped = mapParameter(store.state, 1);
      expect(mapped.event.enable.model).toEqual('2');
      expect(mapped.event.i[0].enable.model).toEqual('0');
    });

    it('setter', () => {
      const mapped = mapParameter(store.state, 1);
      mapped.event.i[0].enable.model = '1';
      expect(store.commit).toBeCalledWith('encoder/writeSettingDirty', {
        index: 1,
        uid_event_i0_enable: '1',
      });
    });
  });
});
